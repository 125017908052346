<template>
    <item-list-view
        :api="api"
        :headers="headers"
        items-key="items"
        id-key="id"
        title="Услуги"
        search-placeholder="Поиск по названию"
        :allowed-search="true"
        :allowed-create="false"
        :allowed-remove="false"
        :create-route="{ name: 'Service', params: { id: 'new' }}"
        update-route="Service"
        remove-title="Удаление услуги"
        remove-message="Вы действительно хотите удалить услугу '<%= item.name %>'?"
    >
        <template v-slot:item.archived="{ item }">
            <v-chip small label dark :color="item.status == 'archived' ? 'red' : 'green'">
                {{ item.status == 'archived' ? 'Да' : 'Нет' }}
            </v-chip>
        </template>
    </item-list-view>
</template>

<script>
import api from '../../services/service'
import ItemListView from '../../components/ItemListView'

export default {
  components: { ItemListView },
  data: () => ({
    api: api,
    headers: [
      { text: 'Название', value: 'name' },
      { text: 'Удалена', value: 'archived' },
      { text: '', value: 'action' }
    ]
  })
}
</script>
